import { React, useState, useEffect } from "react";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import TitleSection from "../../components/title-section/title-section";
import Footer from "../../components/footer/Footer";
import "./contact.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

function Contact() {
  const [tContact] = useTranslation("contact");
  const [tSMetaData] = useTranslation("metaData");

  const navigate = useNavigate();
  const [shipmentType, setShipmentType] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);

  sessionStorage.setItem(
    "routeEs",
    `/${navBarEs["category-services"].contact}`
  );

  sessionStorage.setItem(
    "routeEn",
    `/${navBarEn["category-services"].contact}`
  );

  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    shipmentstype: "",
    contactReason: "",
  });

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleShipmentTypeChange = (e) => {
    setShipmentType(e.target.value);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const {
      name,
      surname,
      email,
      phone,
      shipmentsType,
      contactReason,
      message,
    } = formData;
    let isEmailValid = "";
    if (emailRegex.test(email)) {
      isEmailValid = email !== "";
    }
    const isNameValid = name !== "";
    const isSurnameValid = surname !== "";
    const isPhoneValid = phone !== "";
    const isShipmentTypeValid = shipmentsType !== "";
    const isContactReasonValid = contactReason !== "";
    const isMessageValid = message !== "";
    setIsFormValid(
      isNameValid &&
        isSurnameValid &&
        isEmailValid &&
        isPhoneValid &&
        isShipmentTypeValid &&
        isContactReasonValid &&
        isMessageValid
    );
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const postContact = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(document.getElementById("email").value)) {
      return;
    }
    setLoading(false);
    setErrorMessage(true);
    setSuccessMessage(true);

    const campos = {
      first_name: document.getElementById("name").value,
      last_name: document.getElementById("surname").value,
      email: document.getElementById("email").value,
      phone: document.getElementById("phone").value,
      type_shipment: document.getElementById("shipments-type").value,
      reason_contact: document.getElementById("contact-reason").value,
      message: document.getElementById("message").value,
      status: 1,
    };

    //${process.env.REACT_APP_URL_BACKEND}
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/api/contact/create`,
        campos,
        {
          headers: {
            "Content-Type": "application/json", // Indica que estás enviando datos JSON
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      //Aqui volver a mostrar el botón de enviar mensaje
      setLoading(true);
      if (response.data.code === 0) {
        //Aqui responder con los campos obligatorios
        setErrorMessage(false);
      } else {
        //Aqui mensajito de correo enviado
        // setSuccessMessage(false);
        navigate(`/${tContact("route.route")}`, {
          state: { statusThanks: "true" },
        });
      }
    } catch (error) {
      ///console.error('Error al realizar la solicitud:', error);
      //alert('Hubo un error inesperado.');
      // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje de error al usuario
      navigate(`/${tContact("route.route")}`, {
        state: { statusThanks: "false" },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{tSMetaData("contactanos.title")}</title>
        <meta
          name="description"
          content={tSMetaData("contactanos.description")}
        />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tContact("title.title-page")} />
      <div className="container form-container fs-14 py-5">
        <div className="row g-3">
          <div className="col-md-6 py-0 py-md-3">
            <label htmlFor="name" className="form-label">
              {tContact("form.name")}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control input-info"
              id="name"
              name="name"
              placeholder={tContact("form.name-placeholder")}
              required
              onChange={(e) => handleFormData(e)}
            ></input>
          </div>
          <div className="col-md-6 py-0 py-md-3">
            <label htmlFor="surname" className="form-label">
              {tContact("form.surname")}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control input-info"
              id="surname"
              name="surname"
              placeholder={tContact("form.surname-placeholder")}
              required
              onChange={(e) => handleFormData(e)}
            ></input>
          </div>
          <div className="col-md-6 py-0 py-md-3">
            <label htmlFor="email" className="form-label">
              {tContact("form.email")}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="email"
              className="form-control input-info"
              id="email"
              name="email"
              placeholder={tContact("form.email")}
              required
              onChange={(e) => handleFormData(e)}
            ></input>
          </div>
          <div className="col-md-6 py-0 py-md-3">
            <label htmlFor="phone" className="form-label">
              {tContact("form.phone")}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="number"
              className="form-control input-info"
              id="phone"
              name="phone"
              placeholder={tContact("form.phone")}
              required
              onChange={(e) => handleFormData(e)}
            ></input>
          </div>
          <div className="col-12 py-0 py-md-3">
            <label htmlFor="shipments-type" className="form-label">
              {tContact("form.shipments-type")}
              <span className="text-danger"> *</span>
            </label>
            <select
              className="form-select input-info"
              id="shipments-type"
              name="shipmentsType"
              required
              defaultValue={shipmentType}
              onChange={(e) => {
                handleShipmentTypeChange(e);
                handleFormData(e);
              }}
            >
              <option disabled value="">
                {tContact("form.shipments-type")}
              </option>
              <option value="1">{tContact("shipments-type.domestic")}</option>
              <option value="2">{tContact("shipments-type.imports")}</option>
              <option value="3">{tContact("shipments-type.exports")}</option>
            </select>
          </div>

          <div className="col-12 py-0 py-md-3">
            <label htmlFor="contact-reason" className="form-label">
              {tContact("form.contact-reason")}
              <span className="text-danger"> *</span>
            </label>
            <select
              className="form-select input-info"
              id="contact-reason"
              name="contactReason"
              required
              defaultValue=""
              onChange={(e) => handleFormData(e)}
            >
              <option disabled value="">
                {tContact("form.contact-reason")}
              </option>
              {shipmentType === "1" && (
                <>
                  <option value="1">
                    {tContact("contact-reason.option1")}
                  </option>
                  <option value="2">
                    {tContact("contact-reason.option2")}
                  </option>
                </>
              )}
              {shipmentType === "2" && (
                <>
                  <option value="3">
                    {tContact("contact-reason.option3")}
                  </option>
                  <option value="4">
                    {tContact("contact-reason.option4")}
                  </option>
                </>
              )}
              {shipmentType === "3" && (
                <>
                  <option value="5">
                    {tContact("contact-reason.option5")}
                  </option>
                  <option value="6">
                    {tContact("contact-reason.option6")}
                  </option>
                  <option value="7">
                    {tContact("contact-reason.option7")}
                  </option>
                </>
              )}
            </select>
          </div>

          <div className="col-12 py-0 py-md-3">
            <label htmlFor="message" className="form-label">
              {tContact("form.message")}
              <span className="text-danger"> *</span>
            </label>
            <textarea
              className="form-control input-message"
              id="message"
              placeholder={tContact("form.message-placeholder")}
              rows="7"
              required
            ></textarea>
          </div>
          <p className="text-danger">
            <span className="text-danger"> *</span>
            {tContact("form.required")}
          </p>
          <div className="col-12 py-0 py-md-3 d-flex justify-content-end align-items-center">
            <button
              id="btnSendContact"
              className={`btn-blue auto-with ${loading ? "" : "d-none"} ${
                !isFormValid ? "is-disabled" : ""
              }`}
              onClick={() => {
                postContact();
              }}
              disabled={!isFormValid}
            >
              {tContact("btn-contact.contact")}
            </button>
            <FaSpinner className={`spinner ${!loading ? "" : "d-none"}`} />
          </div>
          <div className="col-12 d-flex justify-content-end align-items-center">
            <p className={`text-danger ${!errorMessage ? "" : "d-none"}`}>
              {tContact("form.error")}
            </p>
            <p className={`text-success ${!successMessage ? "" : "d-none"}`}>
              {tContact("form.success")}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
