import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import "./style.css";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import TitleSection from "../../components/title-section/title-section";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import StepOne from "./step-one/step-one";
import StepTwo from "./step-two/step-two";
import StepThree from "./step-three/step-three";
import StepFour from "./step-four/step-four";
import Summary from "./summary/summary";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";

function GetQuote() {
  const [tNabvar] = useTranslation("navbar");
  const [resOne, setResOne] = useState({});
  const [resTwo, setResTwo] = useState({});
  const [resThree, setResThree] = useState({});
  const [resFour, setResFour] = useState({});
  const [tarifas, setTarifas] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [services, setServices] = useState([]);
  const [stations, setStations] = useState([]);
  const [alertShown, setAlertShown] = useState(false);

  sessionStorage.setItem("routeEs", `/${navBarEs["category-services"].quote}`);

  sessionStorage.setItem("routeEn", `/${navBarEn["category-services"].quote}`);

  const [tSGetQuoteStepOne] = useTranslation("getQuoteStepOne");
  const [tSMetaData] = useTranslation("metaData");
  const listServices = tSGetQuoteStepOne("services", { returnObjects: true });
  const navigate = useNavigate();
  const location = useLocation();
  let alertError = location.state || {};
  let currentLng = "es";

  const getTarifas = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/rate`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        setTarifas(res.data.rates);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPostalCodes = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/postalCode`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        setPostalCodes(res.data.postalCodes);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getServices = async () => {
    let currentLng = "";
    localStorage.getItem("lng")
      ? (currentLng = `${localStorage.getItem("lng")}`)
      : (currentLng = "es");

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/services`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );
      if (res.data.code === 1) {
        const data = res.data.services;

        if (currentLng === "es") {
          const servicesEs = [];
          data.forEach((service) => {
            servicesEs.push(service.service_es);
          });
          setServices(servicesEs);
        } else if (currentLng === "us") {
          const servicesUs = [];
          data.forEach((service) => {
            servicesUs.push(service.service_us);
          });
          setServices(servicesUs);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStations = async () => {
    if (localStorage.getItem("lng")) {
      currentLng = localStorage.getItem("lng");
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=${currentLng}`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY, // Agrega la cadena de texto para la authorization en endponids publicos"
          },
        }
      );

      if (res.data.code === 1) {
        let data = res.data.stations
          .filter((station) => station.add_quote === 1)
          .sort((a, b) => {
            const isMEXA = a.code_text.startsWith("MEX");
            const isMEXB = b.code_text.startsWith("MEX");

            const isEdoMexA = a.name.toLowerCase().startsWith("edomex");
            const isEdoMexB = b.name.toLowerCase().startsWith("edomex");

            if (isMEXA && !isMEXB) return -1;
            if (!isMEXA && isMEXB) return 1;

            if (isMEXA && isMEXB) {
              if (isEdoMexA && !isEdoMexB) return 1;
              if (!isEdoMexA && isEdoMexB) return -1;

              const numA = parseInt(a.code_text.substring(3), 10);
              const numB = parseInt(b.code_text.substring(3), 10);
              return numA - numB;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

            return 0;
          });
        setStations(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTarifas();
    getPostalCodes();
    getServices();
    getStations();
  }, []);

  useEffect(() => {
    if (alertError.alert && !alertShown) {
      Swal.fire({
        title: tSGetQuoteStepOne("alertErrorTitle"),
        text: tSGetQuoteStepOne("alertError"),
        icon: "warning",
        customClass: {
          confirmButton: "custom-confirm-button-class",
        },
      });
      // Marcar que el alerta se ha mostrado
      setAlertShown(true);
      // Reiniciar alertError
      location.state = {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertError.alert, alertShown, location.state]);

  if (tarifas.length !== 0) {
    localStorage.setItem("rates", JSON.stringify(tarifas));
  }

  if (postalCodes.length !== 0) {
    localStorage.setItem("postalCodes", JSON.stringify(postalCodes));
  }

  if (services.length !== 0) {
    localStorage.setItem("services", JSON.stringify(services));
  } else {
    localStorage.setItem("services", JSON.stringify(listServices));
  }

  if (stations.length !== 0) {
    localStorage.setItem("stations", JSON.stringify(stations));
  }

  const handleStepOne = (res) => {
    setResOne(res);
  };

  const handleStepTwo = (res) => {
    setResTwo(res);
  };

  const handleStepThree = (res) => {
    setResThree(res);
  };

  const handleStepFour = (res) => {
    setResFour(res);
  };

  const fusionar = (une, two, three, four) => {
    const fusionado = { ...une, ...two, ...three, ...four };
    return fusionado;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleQuoteSubmit = (data) => {
    const formData = fusionar(resOne, resTwo, resThree, resFour);
    navigate(
      `/${tNabvar("category-services.quote")}/${tNabvar(
        "category-services.ShippingInformation"
      )}`,
      {
        state: { formData },
      }
    );
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{tSMetaData("cotizaEmbarque.title")}</title>
        <meta
          name="description"
          content={tSMetaData("cotizaEmbarque.description")}
        />
      </Helmet>
      <Navbar />
      <TitleSection title={tSGetQuoteStepOne("title")} />
      <section className="shipment__section">
        <form
          className="js-shipment-quote-form"
          autoComplete="off"
          onSubmit={handleSubmit(handleQuoteSubmit)}
        >
          <div className="shipment__layout">
            <div className="shipment__form">
              <StepOne
                onhandleStepOne={handleStepOne}
                dataTwo={resTwo}
                register={register}
                errors={errors}
              />
              <StepTwo
                onhandleStepTwo={handleStepTwo}
                dataOne={resOne}
                dataFour={resFour}
                register={register}
                errors={errors}
              />
              <StepThree
                onhandleStepThree={handleStepThree}
                dataOne={resOne}
                dataTwo={resTwo}
                register={register}
                errors={errors}
              />
              <StepFour
                dataOne={resOne}
                dataTwo={resTwo}
                onhandleStepFour={handleStepFour}
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <Summary data={fusionar(resOne, resTwo, resThree, resFour)} />
        </form>
      </section>
      <Footer />
    </>
  );
}

export default GetQuote;
